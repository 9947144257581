import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { alertAction } from "../../stores/Slices/AlertSlice";


export default function ErrorSnackbar() {
  const dispatch = useDispatch();
  const { errorSnackBarMessage, errorSnackBarOpen } = useSelector(
    (state) => state.alertReducer
  );
  function handleClose() {
    dispatch(alertAction.clearAlert("error"));
  }

  const notify = () =>
    toast.error(errorSnackBarMessage, {
      position: "top-center",
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: handleClose("error"),
    });

  const memoizedCallback = useCallback(() => {
    notify();
  }, [errorSnackBarOpen]);

  useEffect(() => {
    if (errorSnackBarOpen) {
      memoizedCallback();
    }
  }, [errorSnackBarOpen]);

  return <></>;
}
