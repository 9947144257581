import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { alertAction } from "../../stores/Slices/AlertSlice";

export default function SuccessSnackbar() {
  const dispatch = useDispatch();
  const { successSnackBarMessage, successSnackBarOpen } = useSelector(
    (state) => state.alertReducer
  );

  function handleClose() {
    dispatch(alertAction.clearAlert("success"));
  }

  const notify = () =>
    toast.success(successSnackBarMessage, {
      position: "top-center",
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: handleClose("success"),
    });

  const memoizedCallback = useCallback(() => {
    notify();
  }, [successSnackBarOpen]);

  useEffect(() => {
    if (successSnackBarOpen) {
      memoizedCallback();
    }
  }, [successSnackBarOpen]);

  return <></>;
}
